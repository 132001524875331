import React from "react"
import CheckoutPage from "../../components/CheckoutPage/CheckoutPage"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"

const CheckoutIndexPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("nl", "/checkout", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(
          data.checkoutContent.item.content
        )
        return (
          <CheckoutPage
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            breadCrumbs={["menu_home", "menu_checkout"]}
            location={location}
            originPage="checkout"
            language="nl"
            commonContentMap={commonContentMap}
            contentMap={contentMap}
            keywords={Utils.GetKeywordsFromTags(data.checkoutContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default CheckoutIndexPage

const pageQuery = graphql`
  {
    checkoutContent: checkoutContentNlnl {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentNlnl {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
